<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='内容标题' prop='title'>
          <a-input v-model='form.title' />
        </a-form-model-item>
        <a-form-model-item label='内容摘要' prop='precis'>
          <a-input v-model='form.precis' />
        </a-form-model-item>
        <a-form-model-item label='所属栏目' prop='subject'>
          <a-select show-search :value='form.subject' :default-active-first-option='false'
                    :filter-option='false'
                    @focus='handleSearch'
                    @change='handleChange'
                    @search='handleSearch'>
            <a-select-option v-for='item in subjects' :key='item.id'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='内容封面' prop='cover'>
          <a-upload
            list-type='picture-card'
            class='avatar-uploader'
            :show-upload-list='false'
            :customRequest='handleUpload'>
            <img v-if='form.cover' :src='form.cover' alt='cover' width='375px' />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='内容状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio value='offline'>下架</a-radio>
            <a-radio value='online'>上架</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='排序优先级' prop='priority'>
          <a-input-number v-model='form.priority'></a-input-number>
        </a-form-model-item>
        <a-form-model-item label='内容详情' prop='contentRichText'>
          <quill-editor class='editor' ref='myTextEditor'
                        prefix='article/introduce/html/'
                        :value='form.contentRichText'
                        @change='handleQuillChange'>
          </quill-editor>
        </a-form-model-item>
        <a-form-model-item label='内容链接' prop='contentRichTextUrl'>
          <a-input :readOnly='true' v-model='form.contentRichTextUrl' placeholder='系统生成无需手动填写' />
        </a-form-model-item>
        <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
          <a-button @click='handleReset'>
            重置
          </a-button>
          <a-divider type='vertical' />
          <a-button @click='handleCancel'>
            取消
          </a-button>
          <a-divider type='vertical' />
          <a-button type='primary' @click='handleSubmit'>
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import fs from 'string-to-file-stream'
import { QuillEditor } from '@/components'
import client from '@/config/oss.config.js'
import {
  getCampusArticle,
  putCampusArticle,
  queryCampusSubjects,
  getCampusSubject,
  putCampusSubject
} from '@/api/content'
import { combine } from '@/config/quill.config'

export default {
  name: 'CampusEdit',
  data() {
    return {
      subjects: [],
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },
      uuid: this.$uuid.v4(),
      cover: '',
      loading: false,
      form: {
        id: '',
        cover: '',
        title: '',
        status: '',
        precis: '',
        subject: '',
        priority: 0,
        prototype: 'business',
        contentRichText: '',
        contentRichTextUrl: ''
      },
      rules: {
        title: [
          { required: true, message: '请填写内容标题', trigger: 'change' },
          { min: 6, max: 64, message: '内容标题字符数限定6～64个', trigger: 'change' }
        ],
        precis: [
          { required: true, message: '请填写内容摘要', trigger: 'change' },
          { min: 6, max: 128, message: '内容摘要字符数限定6～128个', trigger: 'change' }
        ],
        cover: [{ required: true, message: '请上传内容封面', trigger: 'change' }],
        priority: [{ required: true, message: '请填写排序优先级', trigger: 'change' }],
        subject: [{ required: true, message: '请选择所属栏目', trigger: 'change' }],
        status: [
          { required: true, message: '请选择内容状态', trigger: 'change' }
        ],
        contentRichText: [{ required: true, message: '请编辑内容详情', trigger: 'change' }],
        contentRichTextUrl: [{ required: true, message: '编辑内容详情页面后自动生成', trigger: 'change' }]
      }
    }
  },
  components: { QuillEditor },
  created() {
    this.handleInitial()
    this.$form.createForm(this)
  },
  activated() {
    this.handleInitial()
    this.$form.createForm(this)
    // debugger;
  },
  methods: {
    async handleUpload(event) {
      let result = await client.put('/article/business/' + event.file.uid, event.file)
      this.form.cover = result.url
    },
    async handleQuillChange(html) {
      this.form.contentRichText = html
    },
    handleSearch(keywords) {
      queryCampusSubjects({ current: 1, size: 1000, prototype: 'business', status: 'online', level: 0 }).then(result => {
        this.subjects = result.records
      })
    },
    handleChange(value) {
      this.form.subject = value
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      const { id } = this.$route.query
      if (id) {
        getCampusArticle(this.$route.query).then(result => {
          getCampusSubject({ id: result.subject }).then(subject => {
            this.subjects = Array.of(subject)
            this.form = Object.assign({}, this.form, result)
          })
        })
      }
    },
    async handleSubmit(e) {
      e.preventDefault()

      if (this.form.contentRichText) {
        const path = 'article/business/html/' + this.uuid + '.html'
        const result = await client.putStream(path, fs(combine(this.form.contentRichText), { path: path }))
        this.form.contentRichTextUrl = result.url
      }


      this.$refs.form.validate((result) => {
        if (result) {
          putCampusArticle(this.form).then(res => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
